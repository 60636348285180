.myBankAccount {
  .card-body {
    width: 100%;

    @media (min-width: 992px) {
      width: 75%;
    }
  }
  .rdw-editor-main {
    border: 1px solid #F1F1F1;
    min-height: 200px;
  }
}