.form_comment_edit {
  .form__form-group-field {
    label {
      border: 0;
      background: #646777;
      color: white;
      padding: 8.5px 22px;
      width: 100%;
    }
  }
}