.financialReportEditForm {
  .btn-add,
  .btn-decrease {
    border: 0;
    border-radius: 50%;
    padding: 5px;
    background: rgb(73, 166, 102);
    cursor: pointer;

    &:hover {
      background: rgb(42, 95, 58);
    }
  }

  .validationErrors {
    position: relative;
    top: -18px;
    font-size: 10px;
    line-height: 13px;
    color: rgb(114, 28, 36);
    margin-bottom: -5px;
    display: block;
    margin-top: 5px;
  }

  select {
    padding: 7px 10px;
    border: 1px solid #f2f4f7;
    width: 100%;
    @media (max-width: 480px) {
      min-width: 60px;
    }

    &:focus {
      border-color: #4ce1b6;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .field-working-days,
  .field-currency,
  .field-expense-salary {
    padding: 15px;

    .form__form-group {
      display: flex;
      align-items: center;

      .form__form-group-label {
        margin-bottom: 0;
        white-space: nowrap;
      }

      .form__form-group-field {
        input {
          margin-left: 10px;
        }
      }
    }
  }

  .field-working-days {
    max-width: 23%;
    @media (max-width: 1200px) {
      max-width: 30%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
    }

    .form__form-group {
      .form__form-group-field {
        width: 40%;
        @media (max-width: 992px) {
          width: 100%;
        }

        input {
          min-width: 38px;
          max-width: 38px;
          @media (max-width: 992px) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .field-currency {
    max-width: 17%;
    @media (max-width: 1200px) {
      max-width: 30%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
    }

    .form__form-group {
      .form__form-group-field {
        width: 66%;
        @media (max-width: 992px) {
          width: 100%;
        }

        input {
          min-width: 76px;
          max-width: 76px;
          @media (max-width: 992px) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .field-expense-salary {
    max-width: 30%;
    @media (max-width: 1200px) {
      max-width: 50%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
    }

    .form__form-group {
      .form__form-group-field {
        width: 66%;
        @media (max-width: 992px) {
          width: 100%;
        }

        input {
          min-width: 190px;
          max-width: 190px;
          @media (max-width: 992px) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.form-add-income {
  select {
    padding: 7px 10px;
    border: 1px solid #f2f4f7;
    width: 100%;

    &:focus {
      border-color: #4ce1b6;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

@media (max-width: 480px) {
  .wrapper-btn-decrease {
    display: flex;
    height: fit-content;
    justify-content: flex-end;
  }
}

.c-view-report {
  width: 100%;
  margin: auto;
  overflow: auto;

  &__row {
    .key {
      border-width: 0;
      font-size: 12px;
      color: #646777;
      text-transform: uppercase;
    }

    .value {
      border-width: 0;
      color: #646777;
      font-size: 13px;
    }

    &--header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 19px;

      &:nth-child(odd) {
        background: #edeef1;
      }

      .key {
        color: #646777;
      }
    }

    &--sub {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--string {
      padding-bottom: 10px;
    }

    &__sub-item {
      flex-grow: 1;
      min-width: 50%;
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 10px 19px;
      align-items: center;

      &:nth-child(1),
      &:nth-child(2) {
        &:before {
          height: 0;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: #dbdde5;
        top: -1px;
        left: 0;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background: #dbdde5;
        top: 0;
        left: -1px;
      }

      .key {
        font-size: 11px;

        & > * {
          padding-bottom: 5px;
        }
      }
    }
  }

  &__column {
    display: flex;

    &__sub-item,
    &__header {
      width: 0;
      flex-grow: 1;
      padding: 10px;
      border-right: 1px solid #dee2e6;

      &:first-child {
        border-left: 1px solid #dee2e6;
      }

      * {
        hyphens: auto;
      }
    }

    &__sub-item {
      border-right: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
    }

    &__header {
      background: #eceff7;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;

      &:first-child {
        border-left: 1px solid #dee2e6;
      }

      .key {
        color: #646777;
      }
    }
  }
}

