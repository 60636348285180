.my-reviews-description {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 3px solid #FFC53A;
}

#custom-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  opacity: 0;
}