.skynix-team {
  h2 {
    border-bottom: 1px solid #eceff7;
    padding: 9px 25px 25px 25px;
  }

  .wrapper-content {
    position: relative;
    width: 25%;
    align-items: center;
    font-size: 13px;
    padding: 8px 0 8px 45px;
    margin: 5px 0;

    &:hover {
      &>.skills {
        display: block;
      }
    }

    .name {
      color: #646777;
    }

    .circle {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .red {
      background: red;
    }

    .green {
      background: green;
    }

    .skills {
      display: none;
      position: absolute;
      bottom: 30px;
      left: 40px;
      background: white;
      border-radius: 5px;
      padding: 5px 10px;
      color: #5d6064;
      max-width: 150px;
      box-shadow: 0 0 10px rgba(0,0,0,.1);
    }

    @media (max-width: 992px) {
      width: 33.33%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 520px) {
      width: 100%;
    }
  }
}