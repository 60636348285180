.vacations-history {
  .react-select__menu-list {
    overflow-y: auto !important;
  }
}
@media (max-width: 576px) {
  .vacations-history {
    .form__button-toolbar {
      width: 50%;
      button {
        width: 100% ;
      }
    }
  }
}