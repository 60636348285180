.lead {
  font-size: 13px;
  font-weight: 400;
  background: purple !important;
}

.lead td span, .lead td,
.qualified_lead td span, .qualified_lead td,
.prospect_lead td span, .prospect_lead td,
.client td span, .client td,
.dead_client td span, .dead_client td {
  color: white !important;
  vertical-align: middle;
}

.quoted_client td span, .quoted_client td {
  color: black;
  vertical-align: middle;
}

.lead td,
.qualified_lead td,
.prospect_lead td,
.quoted_client td,
.client td,
.dead_client td {
  padding-left: 10px !important;
}

.qualified_lead {
  background: #f46f25 !important;
}

.prospect_lead {
  background: orange !important;
}

.quoted_client {
  background: #ffea48 !important;
}

.client {
  background: green !important;
}

.dead_client {
  background: #46d8d8 !important;
}

.radio-btn__radio:checked + .radio-btn__radio-custom::before {
  background: rgb(76, 225, 182);
}

.radio-btn:hover .radio-btn__label {
  color: rgb(76, 225, 182);
}

.radio-btn__radio:checked + .radio-btn__radio-custom,
.radio-btn:hover .radio-btn__radio-custom {
  border-color: rgb(76, 225, 182);
}