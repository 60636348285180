.table-users,
.table-projects,
.table-monitoring,
.table-milestones {
  .react-table {
    table {
      tbody {
        tr {
          td {
            padding: 10px;
            vertical-align: middle;
            .status {
              span {
                font-size: 10px;
              }
            }
            .wrapper-usdIcon {
              background: #b8bbc3;
              border-radius: 50%;
              vertical-align: top;
              padding: 2px;
              margin-left: 15px;
              svg {
                vertical-align: text-top;
              }
            }
            &:hover {
              &>.edit-salary {
                opacity: 1;
              }
            }
          }
        }
        .edit-salary {
          opacity: 0;
        }
      }
    }
  }
}
.form_UsersFilter,
.form_WorkHistoryFilter {
  .form_users-filter-buttons,
  .form_work_history-filter-buttons {
    display: flex;
    justify-content: flex-end;
  }
}