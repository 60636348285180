.my-invoices {
  .react-table {
    table {
      tbody {
        tr {
          td {
            img {
              max-width: 15px;
              max-height: 15px;
              width: 100%;
              height: 100%;
              cursor: pointer;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}