.businesses-table {
  table {
    tbody {
      tr {
        vertical-align: middle;
      }
    }
  }
  .business-change-logo-wrapper {
    position: relative;
    display: inline-block;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    .btn-business-change-logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s ease;
      cursor: pointer;
      color: white;
      padding: 0;
      font-size: 10px;
      border: 0;
      outline: 0;
    }
    &:hover {
      .btn-business-change-logo {
        opacity: 1;
      }
    }
  }
}
.business-change-logo-modal {
  img {
    max-height: 240px;
    margin: 0 auto;
  }

  .modal-dialog {
    @media (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .app-container {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  .details,
  .title {
    text-align: center;
  }

  .error {
    margin-top: 4px;
    color: red;
  }

  .has-error {
    border: 1px dotted red;
  }

  .image-container {
    align-items: center;
    display: flex;
    width: 85%;
    height: 80%;
    float: left;
    margin: 15px 10px 10px 37px;
    text-align: center;
  }

  .preview-container {
    height: 335px;
    width: 100%;
    margin-bottom: 40px;
  }

  .placeholder-preview {
    cursor: pointer;
  }

  .placeholder-preview,
  .render-preview {
    text-align: center;
    background-color: #efebeb;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }

  .upload-container {
    cursor: pointer;
    height: 300px;
  }
}
.business-responsive-form {
  width: 50%;
  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}