.leads-single {
    .typography-card .leads-single__title {
        margin: 20px 0 30px;
    }

    .timeline__title {
        text-transform: none;
    }

    &__alert {
        .alert__content {
            display: flex;
            align-items: center;
        }

        .alert__title {
            margin: 0;
        }
    }
}
